import { Component, forwardRef, Output, EventEmitter, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

@Component({
  selector: 'app-gray-rating-input',
  templateUrl: './gray-rating-input.component.html',
  styleUrls: ['./gray-rating-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => GrayRatingInputComponent),
      multi: true
    }
  ]
})
export class GrayRatingInputComponent implements ControlValueAccessor {
  @Output() change = new EventEmitter<number>(); // Emit change event

  rating?: number;
  selectedPoint = 0;
  @Input() stars: ({id: number, point: number})[] = [];
  disabled = false;

  // Implement ControlValueAccessor methods
  onChange = (rating: number[]) => {};
  onTouched = () => {};

  // Set the rating value
  setRating(star: { id: number, point: number }) {
    if (this.disabled) {
      return;
    }

    this.rating = star.id;
    this.selectedPoint = star.point;
    this.onChange([star.id]); // Call the change function
    this.onTouched(); // Mark as touched
    this.change.emit(this.rating); // Emit custom change event
  }

  // Write the value from the form into the component
  writeValue(ratingValue?: number): void {
    this.rating = ratingValue;
    this.selectedPoint = this.stars.find(s => s.id == this.rating)?.point ?? 0;
  }

  // Register the onChange function
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  // Register the onTouched function
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  // Optional: Handle disabled state if needed
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
